const menu = () => {
    const hamburger = document.querySelector('.header .hamburger'),
    menu = document.querySelector('.menu'),
    overlay = document.querySelector('.overlay'),
    links = document.querySelectorAll('.menu .menu__link');

    function toggleMenu() {
        hamburger.classList.toggle('hamburger_active');
        menu.classList.toggle('menu_active');
        overlay.classList.toggle('overlay_active');
    }

    links.forEach(link => {
        link.addEventListener('click', toggleMenu);
    });

    hamburger.addEventListener('click', toggleMenu);
    overlay.addEventListener('click', toggleMenu);
};

export default menu;
const input = () => {
    const form = document.querySelectorAll('form'),
    telInput = document.querySelectorAll('#tel');

    telInput.forEach(input => {
        input. addEventListener('input', () => {
            input.value = input.value.replace(/\D/, '');
        });
    });
};

export default input;
const scrollEffect = () => {
    let scrollPos = 0;

    const car = document.querySelector('.car'),
          list = document.querySelector('.stages__list');

    car.classList.remove("car__up");
    car.classList.remove("car__active");
    list.classList.remove("stage__list__bottom");

    window.addEventListener('scroll', () => { 
        let st = window.pageYOffset;

        if (st > scrollPos) {
            car.classList.remove("car__up");
            car.classList.add("car__active");
        } else {
            car.classList.add("car__up");
            car.classList.remove("car__active");
            list.classList.remove("stage__list__bottom");
        }

        scrollPos = st;
    });
};

export default scrollEffect;
const showmore = () => {
    const cars = document.querySelectorAll('.glide__slides'),
    carsItem = document.querySelectorAll('.glide__slide'),
    btns = document.querySelectorAll('.glide__arrows'),
    showMore = document.querySelectorAll('.show');

    showMore.forEach((link, i) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();

            cars[i].classList.toggle('show__more');
            
            cars[i].querySelectorAll('li').forEach(div => {
                if (div.classList.contains('glide__slide--clone')) {
                    div.style.display = 'none';
                } else {
                    div.classList.toggle('glide__slide_active');
                }

            }); 


            if (link.textContent === "Показати всі") {
                link.textContent = 'Приховати';

                btns[i].style.display = 'none';

            } else {
                link.textContent = 'Показати всі';

                
                cars[i].querySelectorAll('li').forEach(div => {
                    if (div.classList.contains('glide__slide--clone')) {
                        div.style.display = '';
                    }
                }); 

                btns[i].style.display = '';
                cars[i].style.transition = '';
            }
        });
    }); 
};

export default showmore;